<template>
  <div id="first_part">
    <div class="first_part_inner">
      <div class="hero">
        <img src="../assets/images/dots_bg.svg" alt="dots_bg" class="dots_bg">
        <div class="hero_content animate__animated animate__fadeIn animate__slideInDown animate__delay-1s">
          <h1 class="font-60" style="font-weight:900;line-height: 3.5rem;">Front-End Software Developer</h1>
          <p style="line-height: 2rem;">Resolving design problems, building smart user interfaces and useful interactions, developing rich web applications and seamless web experiences.</p>
        </div>
        <img src="../assets/images/techny-coding-sign.png" class="code_abs animate__animated  animate__fadeIn animate__slideInDown animate__delay-1s" alt="">
        <div class="hero_main_image animate__animated animate__fadeIn animate__slideInDown animate__delay-1s">
          <div class="computer_main_image">
            <img src="../assets/images/main_image_first_part.svg" alt="main_image_first_part" class="mx-md-5">
          </div>
        </div>
      </div>
    </div>
    <div class="hero_decor">
      <div class="hero_decor_2">
        <img src="../assets/images/code_bg.svg" alt="code_bg">
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css';
export default {
  name: 'first_part',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes imageMove {
  50% {translate: 0px 30px;}
}
#first_part{
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.first_part_inner {
    width: 98%;
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    z-index: 101;
}
.hero {
    display: flex;
    flex-direction: column-reverse;
    text-align: left;
    position: relative;
    
}
.hero .dots_bg {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -8%;
    width: 60%;
}
.hero .hero_content {
    max-width: 500px;
}

.hero .hero_content h1 {
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 39px;
    line-height: 28px;
}
.hero .code_abs {
  position: absolute;
  bottom: 8rem;
  right: 0;
  left: -350px;
  margin: 0 auto;
  max-width: 150px;
  opacity: .7;
  display: none;
  translate: 0 0;
  animation: imageMove 13s infinite;
}
.hero .hero_main_image {
    margin: 4rem auto 2rem;
}
.computer_main_image img {
    width: 115%;
    height: 100%;
    max-width: 900px;
}
#first_part .hero_decor {
  position: absolute;
  left: -2rem;
  top: 36rem;
  opacity: .4;
  /* transform: rotate(180deg); */
  -webkit-filter: blur(6px);
  filter: blur(6px);
}
@media (min-width: 768px){
  .hero {
      display: flex;
      height: 100vh;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  }
  .hero .hero_content {
    width: 50%;
    max-width: 565px;
    margin: 0;
  }
  .hero .hero_content p {
    display: block;
    padding: 2rem 0;
  }
  .hero .code_abs {
    display: block;
  }
  .hero .hero_main_image {
    margin: 2rem auto 0;
    width: 50%;
  }
  #first_part .hero_decor {
    left: 0rem;
    opacity: .7;
}
}
@media (min-width: 992px){
  .hero .hero_main_image {
    padding: 0 80px 30px 0;
  }
}
@media (min-width: 1140px){
  .hero .hero_content h1 {
      font-size: 60px;
      line-height: 50px;
  }
    .hero .hero_content p {
      padding-right: 7rem;
  }
}
@media (max-width: 768px){
    .computer_main_image img{
        width: 100% !important;
    }
    .hero .dots_bg {
        position: absolute;
        z-index: -1;
        top: -2rem;
        bottom: 0;
        margin: auto;
        right: -10%;
        width: 120%;
    }
    .hero .hero_content h1 {
      margin: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 2rem;
    }
    #first_part .hero_decor{
      position: absolute;
      left: -5rem;
      top: 42rem;
      opacity: .3;
      filter: blur(6px);
    }
    #first_part .hero_decor .hero_decor_2 img{
      width:16rem
    }
}
</style>
