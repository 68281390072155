<template>
    <div class="tableCell">
        <div class="main_content_about">
            <div class="about_section">
                <img
                    src="../assets/images/cube.svg"
                    alt=""
                    class=" d-md-block d-none purple_cube animate__animated animate__slideInUp animate__delay-1s ">
                <img
                    src="../assets/images/sphere.svg"
                    alt=""
                    class="d-md-block d-none purple_blue_sphere animate__animated  ">
                <h1
                    class="about_me font-60 animate__animated animate__slideInUp animate__delay-1s ">
                    <div class="font-60 d-flex">
                        <span class="text-start" style="font-weight:900;letter-spacing: 3px;">Hi,I'm <span class="my_name ">Amirali</span> WebDeveloper</span>
                    </div>
                    <p class="mt-3">Front End Developer / JavaScript Fan / Vue Expert</p>
                </h1>
                <div class="about_info text-start animate__animated  animate__slideInDown animate__delay-1s" style="font-weight:600">
                    <P>Professionally connected with the web development industry.</P>
                    <P>Problem solver, well-organised person, loyal employee with high attention to detail.
                    Fan of Boxing, outdoor activities, video games, and coding of course.</P>
                    <P>Interested in the entire frontend spectrum and working on ambitious projects with interesting people.</P>
                </div>
                <img src="../assets/images/A.svg" alt="" class="A" width="450">
            </div>
        </div>
        <div class="side_about_decor">
            <img src="../assets/images/code_bg.svg" alt="code_bg">
        </div>
    </div>
</template>

<script>
import 'animate.css';
    export default {}
</script>

<style>
    .tableCell {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }
    .main_content_about {
        width: 95%;
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
        position: relative;
        z-index: 101;
    }
    .about_section {
        align-items: center;
        padding: 100px 0;
    }
    .about_section .purple_cube {
        position: absolute;
        opacity: 0.7;
        max-width: 100px;
        bottom: -10px;
        left: 10%;
        z-index: -1;
        display: block;
    }
    .about_section .purple_blue_sphere {
        position: absolute;
        opacity: 0.7;
        max-width: 100px;
        top: 0;
        right: -35rem;
        left: 0;
        margin: auto;
        z-index: -1;
        display: block;
    }
    .about_section .about_me {
        display: flex;
        flex-wrap: wrap;
        max-width: 400px;
    }
    .about_section .about_me p {
        font-weight: 300;
        letter-spacing: 1px;
        color: #f0f8ff;
        opacity: 0.7;
        font-size: 16px;
        line-height: 24px;
    }
    .about_me .font-60 {
        line-height: 75px;
    }
    .about_section .A {
        position: absolute;
        left: 0;
        top: 5rem;
        bottom: 0;
        right: 0;
        z-index: -1;
        margin: auto;
        opacity: .35;
        -webkit-filter: drop-shadow(10px 10px 5px #000);
        filter: drop-shadow(10px 10px 5px #000);
    }
    .about_section .my_name{
        font-family: 'Caveat';
        font-size: 80px;
        filter: drop-shadow(rgb(34, 34, 34) 5px 5px 5px);
        color: #919ff9;
    }
    .side_about_decor{
        position: absolute;
        opacity: .4;
        -webkit-filter: blur(6px);
        filter: blur(6px);
        right: 0;
        /* bottom: -450px; */
        transform: rotate(180deg);
    }
    @media (min-width: 992px) {
        .about_section {
            padding: 0;
        }
        .about_section .purple_blue_sphere {
            top: 3rem;
        }
        .about_section .about_me p {
            font-size: 20px;
        }
        .about_section .about_info {
            margin-right: 100px;
        }
    }
    @media (min-width: 768px) {
        .about_section {
            display: flex;
            justify-content: space-between;
            padding: 200px 0;
        }
        .about_section .purple_cube {
            display: block;
            max-width: 170px;
        }
        .about_section .purple_blue_sphere {
            display: block;
            max-width: 120px;
        }
        .about_section .about_me {
            width: 50%;
            max-width: 100%;
        }
        .about_section .about_me p {
            padding-right: 2rem;
        }
        .about_section .about_info {
            width: 50%;
        }
        .about_section .A {
            margin: auto auto auto 30%;
        }
        .side_about_decor {
            opacity: .7;
            right: 0;
        }
    }
    @media (max-width: 768px){
        .about_section .my_name {
            font-size: 55px;
        }
        .about_me .font-60{
            line-height: 45px;
        }
        .about_section .about_me p{
            text-align: start;
        }
        .about_section .A{
            width: 20rem;
            opacity: .15;
        }
        .about_section .about_info{
            margin-top:1rem;
            font-weight: 500 !important;
            font-size: 14px;
        }
        .side_about_decor img{
            width:16rem;
            transform: rotate(180deg);
        }
        .side_about_decor{
            position: absolute;
            opacity: .4;
            -webkit-filter: blur(6px);
            filter: blur(6px);
            right: -5rem;
            transform: translateY(-3rem);
        }
    }
</style>