<template>
  <div>
    <OverlaySide/>
    <div class="home">
      <div class="fullPage">
        <div>
          <div id="home" class="section table_style">
            <FirstSection/>
          </div>
          <div id="about" class="section table_style">
            <SecondSection/>
          </div>
          <div id="skills" class="section table_style">
            <ThirdSection/>
          </div>
          <div id="portfolio" class="section table_style">
            <ForthSection/>
          </div>
          <div id="contact" class="section table_style">
            <FifthSection/>
          </div>
        </div>
      </div>
      
    </div>
    <div class="navbar2 d-md-flex d-none">
      <div class="btn_up" @click="scrollToDiv('home')">
            <b-icon icon="chevron-up"></b-icon>
            <span class="text-white">Back To Top</span>
        </div>
      </div>
  </div>
  
</template>

<script>
// @ is an alias to /src
import FirstSection from '../components/first_part.vue'
import SecondSection from '../components/second_part.vue'
import ThirdSection from '../components/third_part.vue'
import ForthSection from '../components/forth_part.vue'
import FifthSection from '../components/fifth_part.vue'
import OverlaySide from '../components/overlay_side.vue'
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
  name: 'Main',
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    ForthSection,
    FifthSection,
    OverlaySide,
    Hooper,
    Slide
  },
  methods:{
    // scrollToDiv(id) {
    //     // Scroll to the desired div when the router-link is clicked
    //     const element = document.getElementById(id);
    //     if (element) {
    //         element.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling
    //         const active_btn = document.querySelector('.active_navbar_btn');
    //         active_btn.classList.remove('active_navbar_btn');
    //     }
    // }
  }
}
</script>
<style>
.navbar2 {
  overflow: hidden;
  /* background-color: #333; */
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 9rem;
  justify-content: end;

}
.home{
    height: 100%;
    position: relative;
    /* touch-action: none; */
    transform: translate3d(0px, 0px, 0px);
    transition: all 1200ms cubic-bezier(0.88, 0, 0.265, 1) 0s;
}
.fullPage {
    background-color: #141416;
    color: #f0f8ff;
    position: relative;
    overflow: hidden;
}
.section.table_style {
    display: table;
    table-layout: fixed;
    width: 100%;
}
    .btn_up {
      cursor: pointer;
      border: 0;
      background-color: transparent;
      position: relative;
      padding: 0;
      flex-direction: column;
      align-items: center;
      max-width: 20px;
      min-height: 100px;
      z-index: 100;
      overflow: hidden;
      /* width: 100%; */
      height: 9rem;
      justify-content: center;
      margin-right: 2rem;
    }
    .btn_up span {
        display: block;
        transform: rotate(-90deg);
        color: #f0f8ff;
        position: relative;
        font-size: 13px;
        opacity: .4;
        letter-spacing: 1px;
        min-width: 100px;
        font-family: "Source Sans Pro",sans-serif;
    }
    .btn_up svg {
        transition: transform .3s ease;
        color: white;
        transform: translateY(-2rem);
    }
    .btn_up:hover svg {
        transform: translateY(-2.5rem);
    }
@media (min-width: 992px){
  .fullPage {
    overflow: visible;
  }
  .btn_up {
    display: flex;
  }
}

</style>