<template>
    <!-- <div class="overlay_sides overlay_sides_right">
        <a href="#contact" class="btn_light">Contact</a>
        <div class="nav__wrapper">
            <ul id="side_nav">
                <li data-menuanchor="home" class="passedSection">
                    <a href="#home" title="home">00</a>
                </li>
                <li data-menuanchor="about" class="passedSection">
                    <a href="#about" title="about">01</a>
                </li>
                <li data-menuanchor="skills" class="passedSection">
                    <a href="#skills" title="skills">02</a>
                </li>
                <li data-menuanchor="portfolio" class="passedSection">
                    <a href="#portfolio" title="portfolio">03</a>
                </li>
                <li data-menuanchor="contact" class="passedSection">
                    <a href="#contact" title="contact">04</a>
                </li>
            </ul>
        </div>
        <div>
            <div class="btn_up">
                <b-icon icon="chevron-up"></b-icon>
                <span>Back To Top</span>
            </div>
        </div>
    </div> -->
    <div class="navbar d-flex justify-content-between align-items-center bg-transparent mt-2">
        <div class="px-md-5 px-2 text-white">
            <img src="../assets/logo/logo_top.svg" alt="" style="height:4rem;object-fit:contain">
        </div>
        <div class="px-md-5 px-2">
            <ul class="d-md-flex d-none align-items-center my-0" style="list-style: none;">
                <li data-menuanchor="about" class="mx-2 d-flex box-center p-2">
                    <button @click="scrollToDiv('about')" id="about_nav_btn" title="about" class="pb-1 navbar_btn">About</button>
                </li>
                <li data-menuanchor="skills" class="mx-2 d-flex box-center p-2">
                    <button @click="scrollToDiv('skills')" id="skills_nav_btn" title="skills" class="pb-1 navbar_btn">Skills</button>
                </li>
                <li data-menuanchor="portfolio" class="mx-2 d-flex box-center p-2">
                    <button @click="scrollToDiv('portfolio')" id="portfolio_nav_btn" title="portfolio" class="pb-1 navbar_btn">Projects</button>
                </li>
                <li data-menuanchor="contact" class="mx-2 d-flex box-center p-2">
                    <button @click="scrollToDiv('contact')" id="contact_nav_btn" title="contact" class="pb-1 navbar_btn">Contact</button>
                </li>
                <!-- <li data-menuanchor="contact" class="passedSection">
                    <a href="#contact" title="contact">04</a>
                </li> -->
            </ul>
            <button class="d-md-none contact_btn_small py-2 px-4 rounded-2" @click="scrollToDiv('contact')">
                Contact
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            route: window.location.hash,
            page: 'home'
        }
    },
    watch: {
        route: function(){
            this.page = window.location.hash.split("#/")[1];
        }
    },
    methods:{
        scrollToDiv(id) {
        // Scroll to the desired div when the router-link is clicked
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                // const active_btn = document.querySelector('.active_navbar_btn');
                // active_btn.classList.remove('active_navbar_btn');
                // document.getElementById(`${id}_nav_btn`).classList.add('active_navbar_btn');
            }
        }
    }
}
</script>

<style>
.navbar {
    overflow: hidden;
    position: absolute !important;
    /* background-color: rgba(51, 51, 51, 0); */
    /* position: fixed !important; */
    top: 0;
    width: 100%;
    z-index:10;
    /* background: rgba( 255, 255, 255, 0 );
    backdrop-filter: blur( 17.5px );
    -webkit-backdrop-filter: blur( 17.5px ); */
}

.navbar button {
    float: left;
    display: block;
    color: #f2f2f287;
    text-align: center;
    margin: 0px 5px;
    text-decoration: none;
    font-size: 17px;
    font-weight: 700;
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    outline: none;
    transition: all .3s ease;

}
.navbar button.active_navbar_btn{
    color: white;
}
.navbar button:hover {
    color: white;
}
    .overlay_sides.overlay_sides_right {
        right: 15px;
        z-index: 999;
    }
    .overlay_sides {
        margin: 1rem 0;
        position: fixed;
        top: 0;
        z-index: 100;
    }
    .btn_light {
        background: #f0f8ff;
        padding: 8px 22px;
        display: block;
        border: 2px solid #f0f8ff;
        background-color: transparent;
        border-radius: 6px;
        font-weight: 600;
        text-decoration: none;
        font-size: 17px;
        color: #f0f8ff;
        cursor: pointer;
        transition: all .3s ease-in-out;
    }
    .overlay_sides.overlay_sides_right .nav__wrapper {
        display: none;
    }
    .overlay_sides.overlay_sides_right .nav__wrapper #side_nav {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
    }
    .overlay_sides.overlay_sides_right .nav__wrapper #side_nav:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        border-radius: 50px;
        height: 100%;
        display: block;
        opacity: .4;
        background-color: #f0f8ff;
    }
    .overlay_sides.overlay_sides_right .nav__wrapper #side_nav li {
        transition: all .5s ease-in-out;
    }
    .overlay_sides.overlay_sides_right .nav__wrapper #side_nav li a {
        display: block;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        text-decoration: none;
        font-family: "Source Sans Pro",sans-serif;
        font-size: 13px;
        color: #f0f8ff;
        transition: all .5s ease-in-out;
    }
    .overlay_sides.overlay_sides_right .btn_up {
        display: none;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        position: relative;
        padding: 0;
        flex-direction: column;
        align-items: center;
        max-width: 20px;
        min-height: 100px;
        z-index: 100;
    }
    .overlay_sides.overlay_sides_right .btn_up span {
        display: block;
        transform: rotate(-90deg);
        color: #f0f8ff;
        position: relative;
        font-size: 13px;
        opacity: .4;
        letter-spacing: 1px;
        min-width: 100px;
        font-family: "Source Sans Pro",sans-serif;
    }
    .overlay_sides.overlay_sides_right .btn_up svg {
        transition: transform .3s ease;
        color: white;
        transform: translateY(-2rem);
    }
    .overlay_sides.overlay_sides_right .btn_up:hover svg {
        transform: translateY(-2.5rem);
    }
    .contact_btn_small{
        border: 2px solid #54569c !important;
        font-weight: 900 !important;
        font-size: 16px !important;
        background-color: #54569c !important;
        color: white !important;
    }
    @media (min-width: 992px){
        .overlay_sides.overlay_sides_right {
            align-items: flex-end;
            height: calc(100vh - 2rem);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            right: 3.125rem;
            height: calc(100vh - 6.25rem);
        }
        .overlay_sides {
            margin: 3.125rem 0;
        }
        .overlay_sides.overlay_sides_right .nav__wrapper {
            display: block;
        }
        .overlay_sides.overlay_sides_right .btn_up {
            display: flex;
        }
    }
    @media (min-width: 768px){
        .btn_light {
            padding: 8px 32px;
        }
    }


</style>