<template>
  <section class="tableCell d-flex box-center contact_main">
    <div class="col-11 d-flex box-center">
      <div class="col-md-6 col-12 d-flex box-center contact_section">
        <img class="white_cube1 animate__animated" src="../assets/images/white_cube1.png" alt="white_cube1">
        <div class="contact_cont animate__animated">
          <div class="cont_cont_wrap">
            <!-- <p class="text-start">What would you do if you had a software expert available at your fingertips?</p> -->
            <div class="text-start my-2">
              <span>Want to start new project? Or just start a <strong>journey</strong>.</span>
            </div>
            <div class="text-start">
              <span class="mt-2">
                You can also follow me on
              </span>
              <a class="linkedin" href="https://www.linkedin.com/in/amirali-khalajtehrani" target="_blank">Linkedin</a>.
              <h1 class="mt-4 contact_email">
                <a class="my-mx-3 mx-0" target="_blank" href="mailto:amiralikt98@gmail.com">amiralikt98@gmail.com</a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 d-flex box-center">
        <img src="../assets/images/iphone.svg" alt="" class="iphone_img">
        <img src="../assets/images/mac.svg" alt="" class="mac_img">
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>
.contact_section {
    padding: 100px 0 0;
}
.contact_section .white_cube1 {
  position: absolute;
  max-width: 70px;
  opacity: .7;
  bottom: 0;
  left: 15rem;
  z-index: 1;
  display: none;
  bottom: 9rem;
}
.contact_section .contact_cont {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact_section .linkedin {
    color: #f4a949;
    text-decoration: none;
}
.contact_section h1 {
    font-size: 35px;
    margin: 1.5rem 0;
    font-weight: 800;
}
.contact_section h1 a {
    color: #f0f8ff;
    display: inline-block;
    text-decoration: none;
    transition: color 5s cubic-bezier(.075,.82,.165,1);
}
.contact_section h1 a:hover {
    color: #f4a949;
}
.cont_cont_wrap{
  font-size: 20px;
  margin-bottom: 15rem;
}
.iphone_img{
  position: absolute;
  z-index: 5;
  width: 35rem;
}
.mac_img{
  width: 60rem;
  transform: translate(10rem,-6rem);
}
@media (min-width: 1300px){
  .contact_section .contact_cont {
    margin-left: 26%;
  }
}

@media (min-width: 992px){
  .contact_section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
  }
  .contact_section .contact_cont {
    margin-right: 20%;
    height: 70vh;
}
}
@media (min-width: 768px){
  .contact_section .white_cube1 {
      display: block;
      max-width: 150px;
  }
  .contact_section .contact_cont .cont_cont_wrap {
    max-width: 380px;
}
}
@media (max-width: 768px){
  .contact_main .col-11{
    flex-direction: column;
  }
  .contact_section .contact_cont{
    padding: 0 .5rem;
  }
  .cont_cont_wrap .text-start{
    font-size: 18px;
  }
  .contact_section{
    padding: 0;
  }
  .contact_section h1 a{
    font-size: 30px;
  }
  .cont_cont_wrap{
    margin-bottom: .5rem;
  }
  .mac_img {
    width: 28rem;
    transform: translate(0rem,0rem);
  }
  .iphone_img {
    width: 15rem;
    transform: translate(-5rem, 2.5rem);
}
}

</style>