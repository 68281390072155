<template>
    <!-- <div class="forth_section">
        <section class="panel bg-danger">qweqeqwe123341232131</section>
        <section class="panel bg-success">sadasdasd</section>
    </div> -->
    <hooper  :centerMode="true" :itemsToShow="1" class="forth_section" :wheelControl=false ref="projectsCarousel">
            <slide class="panel">
                <div class="innerin">
                    <div class="portfolio_section">
                        <img class="sphere_lg animate__animated animate__slideInLeft animate__delay-1s" src="../assets/images/sphere_lg.png" alt="sphere_lg">
                        <img src="../assets/images/sphere_md.png" alt="sphere decor"  class="sphere_md animate__animated ">
                        <img class="sphere_sm animate__animated  " src="../assets/images/sphere_sm.png" alt="sphere decor" style="--animate-duration: 700ms; --animate-delay: 300ms;">
                        <div class="portfolio_cont animate__animated  ">
                            <div class="animate__animated animate__slideInLeft animate__delay-1s" style="--animate-duration: 900ms; --animate-delay: 600ms;">
                                <h1 class="font-60 portfolio_text">
                                    <span>Portfolio and Previous</span><br>
                                    <span>Projects</span>
                                </h1>
                                <p class="portfolio_blurb">I have built various different projects to fit different aspects of the client's business. If you want to see more examples of my work than the ones showcased in this site, please 
                                    <a href="#contact">contact me!</a>
                                </p>
                                <div class="proj_link">
                                    <button @click.prevent="mainSlideNext" class="text_white" href="#portfolio/slide2">
                                        See projects
                                        <b-icon icon="arrow-right-short" class="mx-2"></b-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </slide>
            <slide class="panel">
                <div class="innerin d-flex box-center">
                    <button @click="prevSlide()" class="text-white d-flex box-center projects_arrows rounded-circle">
                        <b-icon icon="arrow-left-short"></b-icon>
                    </button>
                    <div class="w-100 position-relative" :class="['slide_hide', { 'slide_show': currentSlide === indx }]" v-for="(project,indx) in projects" :key="indx">
                        <div class="col-md-7 col-12 d-flex box-center">
                            <img class="animate__animated animate__fadeIn projects_image" :src="require(`../assets/projects/${project.image}`)" alt="">
                        </div>
                        <div class="col-md-5 col-12 p-2 d-flex justify-content-start" style="flex-direction:column">
                            <div class="d-md-flex d-none justify-content-start">
                                <img class="animate__animated animate__fadeIn animate__slow" src="../assets/projects/Vector.svg" style="width:9.5rem;transform: translate(-3rem, -2rem);opacity: .5;" alt="">
                            </div>
                            <div class="w-100 mb-md-2 mt-2 mt-md-0">
                                <h3 class="project_header animate__animated animate__fadeIn animate__delay-1s animate__slow">{{project.name}}</h3>
                            </div>
                            <div class="mt-4 d-flex">
                                <a class="text-black p-2 rounded-2 bg-white d-flex box-center fw-semibold animate__animated animate__fadeIn animate__delay-1s animate__slow" style="text-decoration: none;" :href="project.link" target="_blank">
                                    <small>Go to the website</small>
                                    <b-icon icon="arrow-right" class="mx-2"></b-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                    <button @click="nextSlide()" class="text-white d-flex box-center projects_arrows rounded-circle">
                        <b-icon icon="arrow-right-short"></b-icon>
                    </button>
                </div>
            </slide>
            <hooper-pagination slot="hooper-addons" class="pagination_projects"></hooper-pagination>
  </hooper>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';
import 'animate.css';
import Projects from '../../data/projects.json'
export default {
    components: {
        Hooper,
        Slide,
        HooperPagination
    },
    data(){
        return{
            projects:Projects.projects,
            currentSlide:0,
        }
    },
    mounted() {
        setInterval(this.nextSlide,15000);
    },
    methods:{
        nextSlide() {
            this.currentSlide = (this.currentSlide + 1) % 4;
        },
        prevSlide() {
            this.currentSlide = (this.currentSlide - 1) % 4;
        },
        mainSlideNext() {
            this.$refs.projectsCarousel.slideNext();
        },
    }
}
</script>

<style>
.projects_arrows{
    background-color: transparent;
    border: none;
    outline: none;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 30px;
    transition: .3s all ease-out;
}
.projects_arrows:hover{
    background-color: rgba(255, 255, 255, 0.274);
}
    .slide_hide {
        display: none;
        transition: display .5s ease;
        animation: fadeOut;
        /* animate__animated animate__fadeOut */
    }
    .slide_show {
        display: flex;
        transition: display .5s ease;
    }
    .forth_section{
        width: 100%;
        height: 100vh;
        display: flex;
    }
    .forth_section .panel{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 85vh;
        width: 100vw;
    }
    .forth_section .panel .innerin {
        width: 1440px;
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
        position: relative;
        z-index: 101;
    }
    .portfolio_section .sphere_lg {
        position: absolute;
        top: 0;
        right: 10%;
        opacity: .7;
        z-index: -1;
    }
    .portfolio_section .sphere_md {
        position: absolute;
        bottom: 0;
        left: 10rem;
    }
    .portfolio_section .sphere_sm {
        position: absolute;
        top: -100px;
        left: -50%;
        right: 0;
        margin: auto;
    }
    .portfolio_section .portfolio_cont {
        max-width: 700px;
        flex: 1 1 auto;
    }
    .portfolio_section .portfolio_cont h1 {
        margin-top: 10px;
        margin-bottom: 0;
        max-width: 300px;
    }
    .portfolio_section .portfolio_cont .portfolio_blurb {
    padding: 1.5rem 6rem;
    margin: 0;
    line-height: 2rem;
    font-weight: 500;
    }
    .portfolio_section .portfolio_cont a {
        text-decoration: none;
        font-weight: 600;
        color: #f4a949;
        cursor: pointer;
    }
    .portfolio_section .portfolio_cont a:hover {
        color: #DE9639;
    }
    .portfolio_text{
        max-width: 700px;
        font-weight: 900;
        font-size: 4rem;
        line-height: 5rem;
        letter-spacing: 1px;
    }
    .hooper-pagination {
        position: absolute;
        bottom: 10rem !important;
    }
    .project_text {
        /* font-size: 18px; */
        /* text-align: justify; */
        line-height: 2.3rem;
        cursor: default;
    }
    .project_header{
        font-size: 70px;
        text-align: start;
        font-weight: 900;
        background: #2A5EAB;
        background: linear-gradient(to right, #2A5EAB 0%, #C670EF 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: default;
    }
    .pagination_projects ol.hooper-indicators li .hooper-indicator{
        margin: 0 2px !important;
        width: 12px !important;
        height: 12px !important;
        background-color: rgba(255, 255, 255, 0.138) !important;
    }
    .pagination_projects ol.hooper-indicators li .hooper-indicator.is-active{
        background-color: #DE9639 !important;
    }
    .portfolio_section .portfolio_cont .proj_link {
        display: flex;
        justify-content: center;
    }
    .portfolio_section .portfolio_cont .proj_link button {
        text-decoration: none;
        font-weight: 600;
        display: flex;
        align-items: center;
        color: #9c27b0;
        font-size: 20px;
        cursor: pointer;
        background-color: transparent;
        border:none;
        outline: none;
    }
    .portfolio_section .portfolio_cont .proj_link button svg{
        font-size: 2rem;
        transition: all .3s ease;
    }
    .portfolio_section .portfolio_cont .proj_link:hover button svg{
        transform: translateX(5px) !important;
    }
    .projects_image{
        width:42rem;
        height:26rem;
        object-fit: contain;
    }
    @media (min-width: 768px){
        .portfolio_section {
            display: flex;
            justify-content: center;
        }
        .portfolio_section .sphere_lg {
            opacity: 1;
        }
        .portfolio_section .portfolio_cont h1 {
            text-align: center;
            max-width: 100%;
            margin-right: auto;
            margin-left: auto;
        }
        .portfolio_section .portfolio_cont .portfolio_blurb {
            text-align: center;
        }
    }
    @media (min-width: 475px){
        .portfolio_section .portfolio_cont h1 {
    max-width: 700px;
}
    }
    @media (max-width: 768px){
        .portfolio_section .portfolio_cont h1{
            text-align: start;
            max-width: 100%;
            line-height: 2rem;
            padding: 0 .5rem;
        }
        .portfolio_section .portfolio_cont h1 span{
            font-size: 25px !important;
        }
        .portfolio_section .portfolio_cont .portfolio_blurb{
            text-align: start;
            padding: .5rem;
            font-size:15px;
        }
        .portfolio_section .portfolio_cont .proj_link{
            justify-content: start;
        }
        .portfolio_section .portfolio_cont .proj_link button {
            font-size: 18px;
        }
        .projects_image{
            width: 85%;
            height: auto;
        }
        .slide_show{
            flex-direction: column;
        }
        .slide_show .col-12{
            justify-content: center;
            align-content: center;
        }
        .project_header {
            font-size: 35px;
        }
        .project_text {
            font-size: 15px;
            text-align: start;
            line-height: 2rem;
            cursor: default;
        }
    }

</style>